import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import config from "../config/"
import Icon from "./icons"

const { socialMedia } = config

const StyledSocialWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: grid;
    /* Calculate columns, depending on how many profiles there are */
    grid-template-columns: repeat(${({ itemCount }) => itemCount + 1}, auto);
    justify-content: start;
    justify-items: start;

    margin-left: -2.5rem;
    margin-right: -2.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }

    /* Workaround: https://stackoverflow.com/questions/38993170/last-margin-padding-collapsing-in-flexbox-grid-layout */
    &::after {
      content: "";
      width: 2.5rem;
    }

    /* Show scrollbar if desktop and wrapper width > viewport width */
    @media (hover: hover) {
      &::-webkit-scrollbar {
        display: block;
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar:horizontal {
        height: 0.8rem;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 0.2rem solid white;
        background-color: rgba(0, 0, 0, 0.5);
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 8px;
      }
    }
  }

  a {
    margin-right: 0.5rem;
    margin-bottom: 0.75rem;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      margin-right: 1rem;
    }
  }
`

const StyledSocialProfile = styled.a`
  && {
    padding: 0.75rem 1.25rem;
    margin: 0.4rem 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0.5rem 1.25rem;
      margin-right: 1rem;
    }
  }

  width: ${({ width }) => (width ? width : "auto")};
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primary} 50%,
    ${({ theme }) => theme.colors.background} 50%
  );
  background-size: 205% 100%;
  background-position: right bottom;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 0.125rem solid ${({ theme }) => theme.colors.primary};
  padding: ${({ padding }) => (padding ? padding : ".3rem 1.25rem")};
  transition: all 0.1s ease-out;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1rem")};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-position: left bottom;
    color: #ffffff;
  }

  &:hover svg {
    /* Change svg color to white */
    filter: brightness(0) invert(1);
  }

  svg {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    margin-bottom: -0.05rem;
  }

  .email-icon-centered {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.75rem;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      justify-content: center;
    }
  }
`

const Social = ({ width, padding, fontSize, fontWeight, withIcon }) => (
  <StyledSocialWrapper itemCount={socialMedia.length}>
    {socialMedia.map(({ name, url }, key) => {
      return (
        <StyledSocialProfile
          key={key}
          href={url}
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label={name}
          width={width}
          padding={padding}
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {name === "Email" ? (
            <div className="email-icon-centered">
              {withIcon ? <Icon name={name} /> : null} {name}
            </div>
          ) : (
            <>
              {withIcon ? <Icon name={name} /> : null} {name}
            </>
          )}
        </StyledSocialProfile>
      )
    })}
  </StyledSocialWrapper>
)

Social.propTypes = {
  width: PropTypes.string,
  padding: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  withIcon: PropTypes.bool,
}

export default Social
