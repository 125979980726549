import React, { useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion } from "framer-motion"

import { useOnScreen } from "../../hooks/"
import ContentWrapper from "../../styles/ContentWrapper"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 4rem;

  &::before {
    position: relative;
    content: '';
    display: block;
    height:      75px;
    margin-top: -75px;
    visibility: hidden;
  }
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    /* padding: 0 7.5vw; */

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 0 40px;
    }

    .section-title {
      font-size: 2rem;
      margin-bottom: 0rem;
    }

    .section-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .inner-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .text-content {
        width: 95%;
        font-size: 1.125rem;
      }

      .image-content {
        width: 100%;
        margin-left: 0;
        margin-top: 1.5rem;

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          margin-left: 2rem;
          margin-top: 0;
        }
      }

      .about-author {
        border-radius: ${({ theme }) => theme.borderRadius};
        box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.16);
        transition: all 0.3s ease-out;

        &:hover {
          transform: translate3d(0px, -0.125rem, 0px);
          box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.32);
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
          margin: 0 auto;
          height: auto;
          width: 50%;
        }

        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          width: 100%;
        }
      }
    }
  }
`

const About = ({ content }) => {
  const { frontmatter, body } = content[0].node

  // Required for animating the text content
  const tRef = useRef()
  const tOnScreen = useOnScreen(tRef)
  const tVariants = {
    hidden: { opacity: 1, y: 0 },
    visible: { opacity: 1, y: 0 },
  }

  // Required for animating the image
  const iRef = useRef()
  const iOnScreen = useOnScreen(iRef)
  const iVariants = {
    hidden: { opacity: 1, x: 0 },
    visible: { opacity: 1, x: 0 },
  }

  return (
    <StyledSection id="about">
      <StyledContentWrapper>
        <h3 className="section-title">{frontmatter.title}</h3>
        <div className="section-content">
          <motion.div
            className="inner-wrapper"
            ref={tRef}
            variants={tVariants}
            animate={tOnScreen ? "visible" : "hidden"}
          >
            <div className="text-content">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </motion.div>
          <motion.div
            className="image-content"
            ref={iRef}
            variants={iVariants}
            animate={iOnScreen ? "visible" : "hidden"}
          >
            <Img
              className="about-author"
              fluid={frontmatter.image.childImageSharp.fluid}
            />
          </motion.div>
        </div>
      </StyledContentWrapper>
    </StyledSection>
  )
}

About.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default About
