import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { useAnimation } from "framer-motion"

import Context from "../../context/"
import ContentWrapper from "../../styles/ContentWrapper"
import Social from "../social"
import SplashScreen from "../splashScreen"
import Theme from "../../styles/Theme"

const StyledSection = styled.section`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 6rem;
    line-height: initial;
    padding: 0 10vw;

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      padding: 0 2.5rem;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      margin-bottom: 4rem;
    }

    .greetings {
      margin: 0;
      padding: 0;
      line-height: initial;
      font-weight: normal;
      font-size: 1rem;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 1.3rem;
      }
    }

    .title {
      margin: 1rem 0;
      font-size: 3rem;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 4rem;
        margin: 0.5rem 0 0;
      }
    }

    .subtitle {
      margin: 0;
      font-size: 1.5rem;
      font-weight: normal;

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 2rem;
      }
    }

    .description {
      font-size: 1.25rem;

      p {
        margin: 1.5rem 0 2rem;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        max-width: 75%;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        max-width: 57%;
      }
    }
  }
`

const Hero = ({ content }) => {
  const { frontmatter, body } = content[0].node
  const { isIntroDone } = useContext(Context).state

  // Controls to orchestrate animations of greetings, emoji, social profiles, underlining
  const gControls = useAnimation()
  const eControls = useAnimation()
  const sControls = useAnimation()
  const uControls = useAnimation()

  // Start Animations after the splashScreen sequence is done
  useEffect(() => {
    const pageLoadSequence = async () => {
      if (isIntroDone) {
        eControls.start({
          rotate: [0, -10, 12, -10, 9, 0, 0, 0, 0, 0, 0],
          transition: { duration: 2.5, loop: 3, repeatDelay: 1 },
        })
        await gControls.start({
          opacity: 1,
          y: 0,
          transition: { delay: 0.4 },
        })
        await sControls.start({
          opacity: 1,
          x: 0,
        })
        // Animate underlining to hover state
        await uControls.start({
          boxShadow: `inset 0 -2rem 0 ${Theme.colors.secondary}`,
          transition: { delay: 0.4, ease: "circOut" },
        })
      }
    }
    pageLoadSequence()
  }, [isIntroDone, eControls, gControls, sControls, uControls])

  return (
    <StyledSection id="hero">
      {!isIntroDone && <SplashScreen />}
      <StyledContentWrapper>
        <div role="text">
          <h2 className="greetings">{frontmatter.greetings}</h2>
          <h1 className="title">{frontmatter.title}</h1>
          <h2 className="subtitle">{frontmatter.subtitlePrefix} </h2>
        </div>
        <div className="description">
          <MDXRenderer>{body}</MDXRenderer>
        </div>

        <Social fontSize="1rem" width="auto" withIcon />
      </StyledContentWrapper>
    </StyledSection>
  )
}

Hero.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        body: PropTypes.string.isRequired,
        frontmatter: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default Hero
